import React from "react";
import {
  StyledBannerImageWrapper,
  StyledBannerImageMobile,
  StyledBannerImageDesktop,
  StyledBannerTitle,
  StyledBannerSubtitle,  
  StyledBannerLogoMobile,
  StyledBannerLogoDesktop,
  StyledBannerButton,
  StyledBannerContent,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import { StyledSpacer } from "../globalstyle";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "@3beehivetech/components";
import useNavigate from "../../hooks/useNavigate.js";

export default ({
  title,
  subtitle,
  button,
  allImages,
}) => {
  const bgDesktop = allImages.find(e => e.path === "/2020/images/muschio/banner_muschio_desktop.png")?.childImageSharp.fluid;
  const bgMobile = allImages.find(e => e.path === "/2020/images/muschio/banner_muschio_mobile.png")?.childImageSharp.fluid;
  const logoDesktop = allImages.find(e => e.path === "/2020/images/muschio/logo_muschio_desktop.png");
  const logoMobile = allImages.find(e => e.path === "/2020/images/muschio/logo_muschio_mobile.png");

  if (title == null || title == "") {
    return;
  }

  return (
    <StyledGridRow noMargin isFull="true">
      <StyledBannerImageWrapper>
        <StyledBannerImageMobile>
          <BackgroundImage fluid={bgMobile} style={{ position: "initial" }}/>
        </StyledBannerImageMobile>
        <StyledBannerImageDesktop>
          <BackgroundImage fluid={bgDesktop} style={{ position: "initial" }}/>
        </StyledBannerImageDesktop>
        <StyledBannerContent>
          <StyledBannerTitle>{title}</StyledBannerTitle>
          <StyledBannerLogoMobile>
            <GatsbyImage
              image={getImage(logoMobile)}
              alt={logoDesktop.alt}
            />
          </StyledBannerLogoMobile>
          <StyledBannerLogoDesktop>
            <GatsbyImage
              image={getImage(logoDesktop)}
              alt={logoDesktop.alt}
            />
          </StyledBannerLogoDesktop>
          <StyledBannerSubtitle>{subtitle}</StyledBannerSubtitle>
          <StyledBannerButton>
            <Button variant="green" onClick={useNavigate("/owner/muschio-selvaggio/")}>
              {button}
            </Button>
          </StyledBannerButton>
        </StyledBannerContent>
      </StyledBannerImageWrapper>
      <StyledSpacer size={8} />
    </StyledGridRow>
  );
};
