import styled from "styled-components";
import {
  Rem, BP, Ratio, Colors, 
} from "../../commons/Theme";

export const StyledBannerImageWrapper = styled.div`
  width: 90%;
  margin-left:5%;
  position: relative;
  z-index:1;
  border-radius: ${Rem(15)};
`;

export const StyledBannerContent = styled.div`
  position: absolute;
  z-index:2;
  top: ${Rem(25)};
  left: ${Rem(25)};
  right: ${Rem(25)};
  bottom: ${Rem(25)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 42%;
  @media (${BP.tablet}) {
    margin-top: 0;
  }
`;

export const StyledBannerImageDesktop = styled.div`
  ${Ratio(1143, 296)};
  width: 100%;
  height: 100%;
  z-index:1;
  border-radius: ${Rem(15)};
  max-height: ${Rem(296)};
  position: relative;

  display: none;
  @media (${BP.tablet}) {
    display: inline-block;
    width: 100%;
  }
`;

export const StyledBannerImageMobile = styled.div`
  ${Ratio(314, 370)};
  width: 100%;
  height: 100%;
  z-index:1;
  border-radius: ${Rem(15)};
  position: relative;

  @media (${BP.tablet}) {
    display: none;
  }
`;

export const StyledBannerTitle = styled.div`
  text-align: center;
  color: ${Colors.white};
  font-size: ${Rem(16)};
  @media(${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`;

export const StyledBannerLogoMobile = styled.div`
  margin: 0 auto;
  max-width: ${Rem(160)};

  @media (${BP.tablet}) {
    display: none;
  }
`;

export const StyledBannerLogoDesktop = styled.div`
  margin: 0 auto;
  max-width: ${Rem(300)};

  display: none;
  @media (${BP.tablet}) {
    display: inline-block;
    width: 100%;
  }
`;

export const StyledBannerSubtitle = styled.div`
  text-align: center;
  color: ${Colors.white};
  font-size: ${Rem(16)};
  @media(${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`;

export const StyledBannerButton = styled.div`
  text-align: center;
`;
